import { useState, useEffect } from 'react';

/**
 * hook for determining whether or not component mounted (did first-render) or not
 */
const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);
  return didMount;
};

export default useDidMount;
