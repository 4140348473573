import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexof';

/**
 * sort array of objects to match a given order. we specify what key to sort by
 *
 * @param {array} array - array of objects
 * @param {array} order - array of the order we want
 * @param {string} objKey - key we need to sort the array by
 */
const sortArrayOfObjects = (arr, order, objKey) => {
  const sorted = sortBy(arr, obj => indexOf(order, obj[objKey]));
  return sorted;
};

export default sortArrayOfObjects;
