import { parse } from 'papaparse';

import stringToNumber from './stringToNumber';

/**
 * primary function - returns product ids from custom fields or upsell suite csv data
 */
const getProductIds = (productId, customFields, customFieldName, setproductIds) => {
  // do we have custom field values?
  const cfProductIds = customFields.filter(cf => cf.name === customFieldName).map(cf => cf.value); // check custom fields for overrides
  if (cfProductIds.length) return setproductIds([productId, ...cfProductIds].map(stringToNumber)); // return custom field values if we were given any
  // if not, check CSV data
  const getCsvData = () => {
    parse(`/content/upsell-suite/product/${productId}.csv`, {
      download: true,
      header: true,
      complete: results => {
        const csvData = results.data;
        if (csvData.length === 0) return []; // don't render anything until we have product IDS loaded from CSV
        if (csvData.length > 35) return []; // if there was no CSV papaparse is wierd and gives us html of the page as an array... this stop things in that case
        const csvProductIds = csvData.map(row => row.product_id).filter(prodId => prodId.length); // we're good - get just product IDs and remove empty strings
        return setproductIds([productId, ...csvProductIds].map(stringToNumber));
      },
    });
  };
  getCsvData();
};

export default getProductIds;
