import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import DetailOption from './DetailOption';

/**
 * styles
 */
const useStyles = makeStyles({
  fieldset: {
    border: 0,
    padding: 0,
    '&[disabled]': {
      opacity: '0.5',
      pointerEvents: 'none',
    },
  },
  loadingPlaceholder: {
    height: '4px',
  },
});

/**
 * component
 */
const DetailOptions = ({ entityId, onOptionChange, options, selectedOptions, openModal, setOpenModal, loading }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={openModal} onClose={() => setOpenModal(false)} aria-labelledby={`option-modal-${entityId}`}>
      <DialogTitle disableTypography id={`option-modal-${entityId}`}>
        <Typography align="center" variant="h6" component="p">
          Please choose your options
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? <LinearProgress /> : <div className={classes.loadingPlaceholder}></div>}
        <form noValidate>
          <fieldset className={classes.fieldset} disabled={loading} aria-busy={loading}>
            {options.map(({ node }) => (
              <DetailOption key={node.entityId} parentEntityId={entityId} onOptionChange={onOptionChange} value={selectedOptions[node.entityId]} {...node} />
            ))}
          </fieldset>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModal(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailOptions;
