import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import withTagManager from '../utils/withTagManager';

/**
 * styles
 */
const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

/**
 * component
 */
const OverviewActions = ({ products, storeHash, trackImpressions, trackAddToCart }) => {
  const classes = useStyles();

  useEffect(() => {
    trackImpressions(storeHash, products);
  }, []);

  const handleClick = () => {
    const productsToAdd = products.filter(product => product.isSelected);
    trackAddToCart(storeHash, productsToAdd);
  };

  return (
    <Button className={classes.button} color="primary" variant="contained" size="large" onClick={handleClick}>
      Add to Cart
    </Button>
  );
};

export default withTagManager(OverviewActions);