import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

import ImageItem from './ImageItem';

/**
 * styles
 */
const useStyles = makeStyles({
  gridItem: {
    position: 'relative',
    listStyle: 'none',
  },
});

/**
 * component
 */
const ImageList = ({ products }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediumDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={mediumDown ? 2 : 4} component="ul">
      {products.map(({ id, path, images, name, isSelected }, index) => (
        <Grid item className={classes.gridItem} xs={3} component="li" key={id}>
          <ImageItem path={path} images={images} name={name} isSelected={isSelected} showSeparator={index + 1 !== products.length} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageList;
