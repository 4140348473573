import React from 'react';

import Typography from '@material-ui/core/Typography';

import getProductsTotal from '../utils/getProductsTotal';
import formatMoney from '../utils/formatMoney';

/**
 * component
 */
const OverviewTotal = ({ products }) => {
  const currencyCode = products[0].prices.price.currencyCode;
  const total = getProductsTotal(products);

  return (
    <Typography variant="h6" component="p">
      Total Price: {formatMoney(total, currencyCode)}
    </Typography>
  );
};

export default OverviewTotal;
