import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import sortArrayOfObjects from '../utils/sortArrayOfObjects';
import ImageList from './ImageList';
import DetailList from './DetailList';
import OverviewTotal from './OverviewTotal';
import OverviewActions from './OverviewActions';

import { PRODUCTS_QUERY } from '../resolvers/query';

/**
 * styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
  },
}));

/**
 * component
 */
const MainContainer = ({ productIds, storeHash }) => {
  const classes = useStyles();
  // setup product state
  const [products, setProducts] = useState([]);
  // rework the data a bit when we get it, then set it to state
  const handleSetProducts = data => {
    const productData = data.site.products.edges.map(({ node }) => ({ ...node, isSelected: true })); // get just product data and add a "isSelected" key
    const sortedProducts = sortArrayOfObjects(productData, productIds, 'entityId'); // put product dat aback in order we sent it out in
    setProducts(sortedProducts); // set product data to state
  };
  // fetch products
  const { loading, error } = useQuery(PRODUCTS_QUERY, {
    variables: { entityIds: productIds },
    onCompleted: handleSetProducts,
  });
  // while fetching data, show loading
  if (loading || products.length === 0) return <p>Loading...</p>;
  if (error) return null;

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ImageList products={products} />
          </Grid>
          <Grid item xs={12} md={8}>
            <DetailList products={products} setProducts={setProducts} />
          </Grid>
          <Grid item xs={12} md={4} justify="center" alignItems="center" direction="column" container>
            <OverviewTotal products={products} />
            <OverviewActions products={products} storeHash={storeHash} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainContainer;
