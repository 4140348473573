import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import AddIcon from '@material-ui/icons/Add';

/**
 * styles
 */
const useStyles = makeStyles(theme => ({
  card: {
    border: ({ isSelected }) => `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.grey[300]}`,
  },
  image: {
    maxWidth: 200,
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '-10px',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      right: '-11px',
    },
  },
}));

/**
 * component
 */
const ImageItem = ({ path, images, name, showSeparator, isSelected }) => {
  const classes = useStyles({ isSelected });
  const [defaultImg] = images.edges.filter(({ node }) => node.isDefault);
  const image = defaultImg.node || 'fallback-image.jpg';

  return (
    <Card className={classes.card}>
      <CardActionArea href={path}>
        <CardMedia className={classes.image} component="img" image={image.url} alt={image.altText || name} title={image.altText || name} />
      </CardActionArea>
      {showSeparator && <AddIcon className={classes.icon} />}
    </Card>
  );
};

export default ImageItem;
