/**
 * convert strings to number - graphQl needs number product ids to fetch data properly
 */
const stringToNumber = str => {
  const float = parseFloat(str);
  if (float % 1 === 0) {
    const int = parseInt(str, 10);
    return int;
  }
  return float;
};

export default stringToNumber;
