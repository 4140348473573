import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import findIndex from 'lodash/findIndex';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import DetailItem from './DetailItem';
import formatSelectedOptions from '../utils/formatSelectedOptions';

import { SINGLE_PRODUCT_QUERY } from '../resolvers/query';

/**
 * component
 */
const DetailsList = ({ products, setProducts }) => {
  // toggle is active
  const toggleIsSelected = entityId => {
    const updatedProducts = [...products];
    const index = findIndex(updatedProducts, o => o.entityId === entityId);
    updatedProducts[index].isSelected = !updatedProducts[index].isSelected;
    setProducts(updatedProducts);
  };
  // update single item
  const updateProductState = data => {
    const { product } = data.site;
    const updatedProducts = [...products];
    const index = findIndex(updatedProducts, o => o.entityId === product.entityId);
    updatedProducts[index] = {
      ...updatedProducts[index],
      ...product,
    }
    setProducts(updatedProducts);
  };
  const [loadSingleProduct, { loading }] = useLazyQuery(SINGLE_PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: updateProductState,
  });
  const updateProduct = (entityId, selectedOptions) => {
    const variables = {
      entityId,
      optionValueIds: formatSelectedOptions(selectedOptions),
    };
    loadSingleProduct({ variables });
  };

  return (
    <>
      <Typography variant="h6" component="p">
        Please choose options for all selected products
      </Typography>
      <List>
        {products.map(({ id, name, entityId, prices, options, isSelected }) => (
          <DetailItem key={id} name={name} entityId={entityId} prices={prices} options={options.edges} isSelected={isSelected} toggleIsSelected={toggleIsSelected} updateProduct={updateProduct} loading={loading} />
        ))}
      </List>
    </>
  );
};

export default DetailsList;
