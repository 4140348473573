const formatSelectedOptions = selectedOptions => {
  const result = [];
  for (let [key, value] of Object.entries(selectedOptions)) {
    result.push({
      optionEntityId: Number(key),
      valueEntityId: Number(value),
    });
  }
  return result;
};

export default formatSelectedOptions;
