import React, { useState, useEffect } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DetailOptions from './DetailOptions';
import DetailSelectedOptions from './DetailSelectedOptions';
import useDidMount from '../utils/useDidMount';
import formatMoney from '../utils/formatMoney';

/**
 * component
 */
const DetailItem = ({ name, entityId, prices, options, isSelected, toggleIsSelected, updateProduct, loading }) => {
  // options modal
  const [openModal, setOpenModal] = useState(false);
  // options
  const [selectedOptions, setselectedOptions] = useState({});
  const onOptionChange = (optionEntityId, value) => {
    setselectedOptions({
      ...selectedOptions,
      [optionEntityId]: value,
    });
  };
  // update product data when selected options change
  const didMount = useDidMount(); // did this component mount already?
  useEffect(() => {
    if (!didMount) return;
    updateProduct(entityId, selectedOptions);
  }, [selectedOptions]); // Only re-run the effect if these things change (selectedOptions is all we need but react yelled at me until i added other vars)
  // price stuff
  const { value, currencyCode } = prices.price;
  // helper var
  const hasOptions = options.length !== 0;

  return (
    <>
      <ListItem role={undefined} dense button onClick={() => toggleIsSelected(entityId)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple inputProps={{ 'aria-label': `Checkbox ${name}` }} color="primary" />
        </ListItemIcon>
        <ListItemText
          id={entityId}
          primary={
            <Typography variant="subtitle1" component="p">
              {name}{' '}
              <Typography variant="subtitle1" component="span" color="error">
                {formatMoney(value, currencyCode)}
              </Typography>
            </Typography>
          }
          secondary={hasOptions && isSelected ? <DetailSelectedOptions options={options} selectedOptions={selectedOptions} /> : ''}
        />
        {hasOptions && (
          <ListItemSecondaryAction>
            <Button color="primary" variant="outlined" size="small" onClick={() => setOpenModal(true)}>
              Choose Options
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {hasOptions && <DetailOptions entityId={entityId} onOptionChange={onOptionChange} options={options} selectedOptions={selectedOptions} openModal={openModal} setOpenModal={setOpenModal} loading={loading} />}
    </>
  );
};

export default DetailItem;
