import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

/**
 * styles
 */
const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(0.5),
  },
}));

/**
 * component
 */
const DetailSelectedOptions = ({ options, selectedOptions }) => {
  const classes = useStyles();

  return options.reduce((optionComps, { node: option }) => {
    const selectedEntityId = selectedOptions[option.entityId] || 0;
    if (selectedEntityId !== 0) {
      const selectedLabel = option.values.edges.filter(({ node: value }) => value.entityId === selectedEntityId)[0].node.label;
      optionComps.push(<Chip key={option.entityId} className={classes.chip} label={selectedLabel} variant="outlined" size="small" component="span" />);
    }
    return optionComps;
  }, []);
};

export default DetailSelectedOptions;
