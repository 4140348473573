import React, { useState, useEffect } from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import { ThemeProvider } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import theme from './theme';
import getProductIds from './utils/getProductIds';
import MainContainer from './components/MainContainer';

/**
 * meta
 */
const VERSION = '2.0.0';
console.log('IntuitSolutions.net - Frequently Bought Together', VERSION);

/**
 * component
 */
const App = ({ productId, customFields, token, storeHash }) => {
  const [productIds, setproductIds] = useState([]);
  useEffect(() => {
    getProductIds(productId, customFields, 'fbt-product', setproductIds);
  }, [productId, customFields]);
  if (!productIds.length) return null; // return nothing if we don't have custom fields or CSV data

  const client = new ApolloClient({
    headers: {
      Authorization: `Bearer ${token.trim()}`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Typography variant="h5" component="h5" align="center" color="textPrimary" paragraph>
          Frequently Bought Together
        </Typography>
        <MainContainer productIds={productIds} storeHash={storeHash} />
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
