/**
 * return total of products given
 *
 * @param {object} products - bigcommerce product objects
 */
const getProductsTotal = products => {
  const total = products.reduce((runningTotal, product) => {
    if (product.isSelected) {
      runningTotal += product.prices.price.value;
    }
    return runningTotal;
  }, 0);
  return total || 0;
}

export default getProductsTotal;
