import React from 'react';
import TagManager from 'react-gtm-module';

import getProductsTotal from '../utils/getProductsTotal';
import config from '../config';

/**
 * initialize GTM once
 */
const { gtmId, dataLayerName, eventPrefix } = config.analytics;
TagManager.initialize({
  gtmId: gtmId, // our gtm property
  dataLayerName: dataLayerName, // unique name so we don't hijack client's "dataLayer" on window
});
window[dataLayerName] = window[dataLayerName] || [];

/**
 * pass the functionality on to any comps we want to
 */
const withTagManager = WrappedComponent => props => {
  // track impressions
  const trackImpressions = (storeHash, products) => {
    window[dataLayerName].push({
      event: `${eventPrefix}_impressions`,
      storeHash: storeHash,
      value: getProductsTotal(products),
      ecommerce: {
        currencyCode: products[0].prices.price.currencyCode || 'USD',
        impressions: products.map((product, index) => ({
          id: product.entityId + '',
          name: product.name,
          price: product.prices.price.value + '',
          variant: product.sku,
          position: index + 1,
        })),
      },
    });
  };

  // track adding to cart
  const trackAddToCart = (storeHash, products) => {
    window[dataLayerName].push({
      event: `${eventPrefix}_addToCart`,
      storeHash: storeHash,
      value: getProductsTotal(products),
      ecommerce: {
        currencyCode: products[0].prices.price.currencyCode || 'USD',
        add: {
          products: products.map(product => ({
            id: product.entityId + '',
            name: product.name,
            price: product.prices.price.value + '',
            variant: product.sku,
            quantity: 1,
          })),
        },
      },
    });
  };

  return <WrappedComponent {...props} trackImpressions={trackImpressions} trackAddToCart={trackAddToCart} />;
};

export default withTagManager;