import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

/**
 * component
 */
const DetailOption = ({ value, parentEntityId, onOptionChange, entityId, displayName, isRequired, values }) => (
  <TextField id={`select-${parentEntityId}-${entityId}`} select fullWidth margin="normal" variant="outlined" color="primary" label={displayName} value={value || ''} required={isRequired} onChange={event => onOptionChange(entityId, event.target.value)}>
    {values.edges.map(({ node }) => (
      <MenuItem key={node.entityId} value={node.entityId}>
        {node.label}
      </MenuItem>
    ))}
  </TextField>
);

export default DetailOption;
