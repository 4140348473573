import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const its__fbtEl = document.getElementById('its__fbt');

if (its__fbtEl !== null) {
  const its__fbtToken = its__fbtEl.dataset.token || '';
  const its__fbtStoreHash = its__fbtEl.dataset.storehash || '';
  const its__fbtProductId = Number(its__fbtEl.dataset.productid) || 0;
  const its__fbtCustomFieldsString = its__fbtEl.dataset.customfields || '[]';
  const its__fbtCustomFields = JSON.parse(its__fbtCustomFieldsString);

  if (its__fbtToken && its__fbtToken.length) {
    ReactDOM.render(<App token={its__fbtToken} storeHash={its__fbtStoreHash} productId={its__fbtProductId} customFields={its__fbtCustomFields} />, its__fbtEl);
  }
}
