import { gql } from 'apollo-boost';

/**
 * query multiple products
 */
const PRODUCTS_QUERY = gql`
  query PRODUCTS_QUERY($entityIds: [Int!]) {
    site {
      products(entityIds: $entityIds, first: 4) {
        edges {
          node {
            id
            entityId
            sku
            path
            name
            prices {
              price {
                value
                currencyCode
              }
              salePrice {
                value
                currencyCode
              }
              retailPrice {
                value
                currencyCode
              }
            }
            images {
              edges {
                node {
                  url(width: 250, height: 250)
                  altText
                  isDefault
                }
              }
            }
            options {
              edges {
                node {
                  entityId
                  displayName
                  isRequired
                  values {
                    edges {
                      node {
                        entityId
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * query single product
 */
const SINGLE_PRODUCT_QUERY = gql`
  query SINGLE_PRODUCT_QUERY($entityId: Int!, $optionValueIds: [OptionValueId!]) {
    site {
      product(entityId: $entityId, optionValueIds: $optionValueIds) {
        id
        entityId
        sku
        path
        name
        prices {
          price {
            value
            currencyCode
          }
          salePrice {
            value
            currencyCode
          }
          retailPrice {
            value
            currencyCode
          }
        }
        images {
          edges {
            node {
              url(width: 250, height: 250)
              altText
              isDefault
            }
          }
        }
        options {
          edges {
            node {
              entityId
              displayName
              isRequired
              values {
                edges {
                  node {
                    entityId
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { PRODUCTS_QUERY, SINGLE_PRODUCT_QUERY };
